<template>
  <div>
    <div class="card pb-4">
      <div class="card-header">
        <div class="utils__title">
          <strong>Aktualizace</strong>
        </div>
      </div>
    </div>
    <div v-if="items.data !== undefined && items.data.length > 0">
      <div :id="'release-note-'+item.id" :key="'release-note-'+item.id" v-for="item in items.data" class="card">
        <div class="card-header">
          <div :class="['utils__title', $style.utils__title]"># {{ item.identifier }} - {{ item.title }}</div>
          <span class="p-2 bg-light">{{ toLocaleDate(item.created_at) }}</span>
          <div class="mt-4" v-for="category in item.categories === null ? [] : item.categories" :key="'cat'+category.id">
            <h5>[{{category.name}}]:</h5>
            <ul class="mt-3">
              <li :key="'release-note-item' + note.id" v-for="note in category.items">{{ note.description }}.</li>
            </ul>
          </div>
        </div>
      </div>
      <a-button v-if="itemsNextCount > 0" :loading="loading" type="primary" class="d-block m-auto"
                @click="loadNext()">Načíst dalších {{ itemsNextCount }} (zbývá {{ items.count - items.data.length }})
      </a-button>
    </div>
    <div v-else class="card">
      <a-empty class="pt-4 pb-4">
        <a-spin v-if="loading"/>
      </a-empty>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      loading: false,
    }
  },
  computed: {
    itemsNextCount: function () {
      const count = this.items.count - this.items.data.length
      return count > this.items.per_page ? this.items.per_page : count
    },
    page: function () {
      return this.$store.getters['releaseNotes/getPage']
    },
    items: function () {
      return this.$store.getters['releaseNotes/getItems']
    },
  },
  methods: {
    loadNext() {
      this.loading = true
      this.$store.commit('releaseNotes/nextPage')
      this.$store.dispatch('releaseNotes/getList', this.page)
        .finally(() => {
          this.loading = false
          this.loaded = true
        })
    },
    toLocaleDate: function (text) {
      return this.$options.filters.formatDate(text)
    },
  },
  created() {
    if (this.items.data.length === 0) {
      this.loadNext()
    }
  },
}
</script>

<style lang="scss" module scoped>
  .utils__title {
    font-size: 2em;
    font-weight: bold;
  }
</style>
